// p{
//   &+ul{
//     margin-top: -1rem;
//   }
//   &+ol{
//     margin-top: -1rem;
//   }
// }

@media (min-width: 1200px){
    .px-xl-6{
        padding-left: 4rem!important;
        padding-right: 4rem!important;
    }
}

.row-eq-height > [class*='col-'] {
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

li > ol, li > ul{
    margin-top: 0.5rem;
}