// navigation bar
.nav-bg{
  background: #272a34;
}

@media (max-width: 599px){
  .nav-home, .nav-about{
    text-align: left!important;
  }

  .nav-reading, .nav-thought{
    text-align: right!important;
  }

  .nav-search, .nav-lang{
    margin-top: 10px;
  }

  .navbar-custom i{
    margin-right: 5px;
  }

  .nav-search i{
    margin-right: 0;
  }
}

.navbar{
  padding: 0 1rem;

  @media (max-width: 991px){
    padding: .5rem 1rem 1.1rem 1rem;
  }

  a{
    color: #a1a7b9;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 33.33333%;
    flex: 1 1 33.33333%;
    text-align: center;
    padding: 0.5rem 0.25rem;
    &:hover{
      color: #fff;
      background: #20232b;
      text-decoration: none;
    }
    @media (min-width: $s-sm + 24px){
      -webkit-box-flex: 1;
      flex: 1;
      -ms-flex: 1;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -ms-flex-direction: column;
      display: flex;
      font-size: 1.1rem;
      padding: 1rem 0.5rem;
      height: 100%;
      text-align: center;
    }
  }
  @media screen and (max-width: $s-xs + 150px){
    font-size: .95rem;
  }
  @media screen and (max-width: $s-md){
    & .container{
      max-width: 100%;
      padding: 0;
    }
  }
}

.nav-search{
    display: block;
    margin-left: 10px;

    @media (max-width: 991px){
      width: calc(100% - 40px);
      margin-left: 0;
    }

    @media screen and (max-width: $s-xs + 150px){
      font-size: .95rem;
      height: 40px;
    }
    
    .nav-search-input{

      @media (max-width: 991px){
        width: calc(100% - 39px);
      }

      border: 0;
      border-radius: 6px;
      background: #3d4251;
      color: #fff;
      font-size: 1.1rem;
      padding: 1rem;
      height: 45px;
      width: 200px;
      display: inline-block;

      &:focus{
        outline: none;
        border: 0;
        background: #484d60;
      }
      &:hover{
        background: #484d60;
      }
      @media (min-width: $s-xl){
        width: 300px;
      }
    }
    .nav-search-submit{
      background: 0 0;
      border: 0;
      padding: 0;
      text-align: center;
      font-size: 1.1rem;
      color: #aaa;
      font-weight: 700;
      display: inline-block;
      cursor: pointer;

      &:hover{
        background: 0 0;
        color: $c-green;
      }

      &:focus{
        border: 0;
        outline: none;
      }

      @media (max-width: 599px) {
        margin-left: 10px;
      }
    }
}

.nav-lang{

  @media screen and (max-width: $s-xs + 150px){
    font-size: .95rem;
  }

  color: #fff;
  width: 2rem;
  margin-left: .5rem;
  font-size: 1rem;
  font-weight: 600;
  padding: 1px 0 2px 0;
  line-height: 1.3;
  text-align: center;

  span{
    color: #bbb;
  }

  a{
    padding: 0;
    font-size: initial;
    color: #aaa;
    &:hover{
      background: initial;
      color: $c-green;
    }
  }

  .active{
    color: #fff;
  }
}