// main color
$c-green: #5eeda3;
$c-red: #fc5d76;
$c-blue: #3292f6;
$c-yellow: #fdb54b;
$c-violet: #bd96f6;

$c-blue-text: #4682b4;
$c-gray-text: #778899;
$c-yellow-text: #ffeead;
$c-gray-hover: #555;
$c-a-hover: #0085A1;
$c-sec-bg: #F4F4F4;

// size grid
$s-xs: 350px;
$s-sm: 576px;
$s-md: 768px;
$s-lg: 992px;
$s-xl: 1200px;

// font
$f-main: 'Open Sans', sans-serif;
$f-size: 1.1rem;
$f-size-small: 0.9rem;

$f-semi-bold: 600;
$f-bold: 700;
$f-extra-bold: 800;

// navigation bar
@import 'tnavigation'; // _tnavigation.scss

  .nav-home:hover .fa, .nav-home.nav-current .fa{
    color: #ffeead;
  }
  .nav-home.nav-current{
    color: #fff;
    text-decoration: none;
  }

  .nav-tutorial:hover .fa, .nav-tutorial.nav-current .fa{
    color: #fc5d76;
  }
  .nav-tutorial.nav-current{
    color: #fff;
    text-decoration: none;
  }

  .nav-reading:hover .fa, .nav-reading.nav-current .fa{
    color: #5eeda3;
  }
  .nav-reading.nav-current{
    color: #fff;
    text-decoration: none;
  }

  .nav-about:hover .fa, .nav-about.nav-current .fa{
    color: #3292f6;
  }
  .nav-about.nav-current{
    color: #fff;
    text-decoration: none;
  }

  .nav-sketch:hover .fa, .nav-sketch.nav-current .fa{
    color: #fdb54b;
  }
  .nav-sketch.nav-current{
    color: #fff;
    text-decoration: none;
  }

  .nav-thought:hover .fa, .nav-thought.nav-current .fa{
    color: #bd96f6;
  }
  .nav-thought.nav-current{
    color: #fff;
    text-decoration: none;
  }


// only for vn site
@import 'thi-vn'; // _thi-vn.scss
@import 'tsection'; // _tsection.scss
@import 'tpagination'; // _tpagination.scss
@import 'tfooter'; // _tfooter.scss
@import 'tgrid'; // _tgrid.scss
@import 'font';
@import 'code';
@import 'box';
@import 'tspacing';


// back to top
.back-to-top{
  margin-right: 15px;
  position: absolute;
  right: 0;
  color: #a9a9a9!important;
  cursor: pointer;
  &:hover{
    color: #555!important;
  }
}