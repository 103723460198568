// grid extra small (xs)
@media (min-width: $s-xs) and (max-width: $s-sm - 1px){
  .col-xs-6{
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col-xs-4{
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
  }
}